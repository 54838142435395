<template>
  <div class="relative">
    <div ref="anchorRef" @click="openMenu">
      <slot :selected-item @click="openMenu">
        <BaseButton color="secondary" class="w-full md:w-auto">
          <slot v-if="selectedItem" :name="selectedItem.label">
            {{ selectedItem.label }}
          </slot>
          <div v-else class="text-primary">Maak een selectie</div>
        </BaseButton>
      </slot>
    </div>
    <Teleport to="body">
      <EscapeOverlay
        :active="menuVisible"
        :shaded="shadedOverlay"
        class="z-50"
        @escape="onCancel"
        @click.stop
      />
      <BaseCard
        v-if="menuVisible"
        ref="menuRef"
        class="absolute z-50 w-full md:w-96"
        :style="floatingStyles"
      >
        <InputItemsFilter
          ref="filterListRef"
          v-model="pendingSelection"
          :items
          :use-cards
          :all-open
        >
          <!-- Pass any slot content to InputItemsFilter slots of the same name -->
          <template v-for="(_, name) in $slots" #[name]="slotProps">
            <slot :name="name" v-bind="slotProps" />
          </template>
        </InputItemsFilter>
        <BaseDivider />
        <div class="flex items-center justify-between gap-2 p-4">
          <BaseButton color="primary" small @click="onConfirm">
            Bevestigen
          </BaseButton>
          <BaseButton color="secondary" small @click="onCancel">
            Annuleren
          </BaseButton>
        </div>
      </BaseCard>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { useFloating, offset, shift } from "@floating-ui/vue"
import {
  BaseCard,
  BaseButton,
  BaseDivider,
  EscapeOverlay,
  InputItemsFilter,
} from "@repowerednl/ui-component-library"

const props = defineProps({
  /**
   * Must have the following structure:
   * [
   *   { label: "label", value: "value" },
   *   ...
   * ]
   *
   * An item can optionally have a 'parent' key that references another item's
   * label key. This will group the item under the referenced item.
   *
   * Items can be hidden by adding a 'hidden' boolean property.
   */
  items: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every((item) => {
        return Object.hasOwn(item, "label") && Object.hasOwn(item, "value")
      })
    },
  },
  /**
   * Render each item as a card, allowing for more complex slot content.
   */
  useCards: Boolean,
  /**
   * Start with all groups open.
   */
  allOpen: Boolean,
  /**
   * Show a shaded overlay when the menu is open, drawing attention to the menu.
   */
  shadedOverlay: {
    type: Boolean,
    default: false,
  },
})

// eslint-disable-next-line vue/require-prop-types
const confirmedSelection = defineModel({ default: null })

const emit = defineEmits(["confirmed", "cancelled"])

defineExpose({
  openMenu,
})

const FLOAT_PADDING = 12

const anchorRef = ref()
const menuRef = ref()
const filterListRef = ref()
const pendingSelection = ref([])
const menuVisible = ref(false)

const { floatingStyles } = useFloating(anchorRef, menuRef, {
  placement: "bottom",
  middleware: [offset(FLOAT_PADDING), shift({ padding: FLOAT_PADDING })],
})

/**
 * Item object corresponding to the selected value
 */
const selectedItem = computed(() => {
  return props.items.find((item) => item.value === confirmedSelection.value)
})

function openMenu() {
  pendingSelection.value = [confirmedSelection.value]
  menuVisible.value = true
}

function onConfirm() {
  menuVisible.value = false
  // Only apply if selection has changed
  if (pendingSelection.value[0] !== confirmedSelection.value) {
    confirmedSelection.value = pendingSelection.value[0]
    emit("confirmed")
    return
  }
  emit("cancelled")
}

function onCancel() {
  menuVisible.value = false
  emit("cancelled")
}

/**
 * Update pending selection when confirmed selection changes
 */
watch(
  confirmedSelection,
  (newSelection) => (pendingSelection.value = [newSelection]),
  { immediate: true },
)

/**
 * Focus search input when menu is opened
 */
watch(filterListRef, (newRef) => {
  if (newRef) {
    filterListRef.value.focusSearch()
  }
})
</script>
