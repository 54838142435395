<template>
  <div class="flex flex-col gap-4">
    <!-- Header -->
    <TextHeader>Resultaten</TextHeader>
    <!-- Filter bar -->
    <ToolbarComponent with-portfolio no-assets>
      <ExportComponent
        :metering-point="filterStore.currentMeteringPoint"
        :date-range="filterStore.dateRange"
        type="results"
      />
    </ToolbarComponent>
    <!-- Chart panel -->
    <ResultsChartPanel
      :metering-point="filterStore.currentMeteringPoint"
      :date-range="filterStore.dateRange"
      :mode="filterStore.mode"
    />
    <!-- Table panel -->
    <BaseDivider />
    <ResultsTablePanel
      :metering-point="filterStore.currentMeteringPoint"
      :date-range="filterStore.dateRange"
      :mode="filterStore.mode"
    />
  </div>
</template>

<script setup>
import { onBeforeMount } from "vue"
import { startOfToday, startOfMonth, lastDayOfMonth } from "date-fns"
import useFilterStore from "@/stores/filterStore.js"
import { TextHeader, BaseDivider } from "@repowerednl/ui-component-library"
import ToolbarComponent from "@/components/navigation/ToolbarComponent.vue"
import ExportComponent from "@/components/information/ExportComponent.vue"
import ResultsChartPanel from "@/components/panels/ResultsChartPanel.vue"
import ResultsTablePanel from "@/components/panels/ResultsTablePanel.vue"

const filterStore = useFilterStore()

onBeforeMount(() => {
  filterStore.currentMeteringPoint = null
  filterStore.dateRange = [
    startOfMonth(startOfToday()),
    lastDayOfMonth(startOfToday()),
  ]
})
</script>
