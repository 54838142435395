export default {
  "dev-json": {
    API_URL: "http://localhost:3000",
  },
  "dev-django": {
    API_URL: "http://localhost:8080",
  },
  test: {
    API_URL: "repowered.test",
  },
  dev: {
    API_URL: "",
  },
  prod: {
    API_URL: "",
  },
}[import.meta.env.MODE ?? "prod"]
